<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8" :xs="24">
        <div class="mb-3">
          <p class="text-gray-500 mb-1">Дэмпинг</p>
          <el-checkbox v-model="damping.damping"></el-checkbox>
        </div>
      </el-col>
      <el-col :span="8" :xs="24">
        <div class="mb-3" :class="{ 'has-error': $v.damping.min_price.$error }">
          <p class="text-gray-500 mb-1">Мин. Цена</p>
          <el-input
            :disabled="!damping.damping"
            type="number"
            v-model.number="$v.damping.min_price.$model"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="8" :xs="24">
        <div class="mb-3" :class="{ 'has-error': $v.damping.step.$error }">
          <p class="text-gray-500 mb-1">Шаг</p>
          <el-input
            :disabled="!damping.damping"
            type="number"
            v-model.number="$v.damping.step.$model"
          ></el-input>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minValue, integer } from "vuelidate/lib/validators";
export default {
  name: "DampingSettings",
  mixins: [validationMixin],
  props: ["damping"],
  validations() {
    if (this.damping.damping) {
      return {
        damping: {
          min_price: { required, integer, minValue: minValue(1) },
          step: { required, integer, minValue: minValue(1) },
        },
      };
    }
    return {
      damping: {
        min_price: {},
        step: {},
      },
    };
  },
  methods: {
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit("error");
      }
    },
  },
};
</script>

<style scoped></style>
