import apiClient from "@/api/apiClient";

export const getProducts = async (filters) => {
  const result = await apiClient.get("/api/goods", {
    params: {
      ...filters,
    },
  });
  return result.data;
};
