<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        {{ (product && product.title) || "Создать продукт" }}
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div>
        <el-button
          @click="() => $router.back()"
          size="small"
          icon="el-icon-back"
        >
          Назад
        </el-button>
      </div>
    </h1>

    <el-form label-position="top">
      <div class="border p-5 mb-5 rounded-md">
        <el-row :gutter="20">
          <el-col :span="12" :xs="24">
            <div class="mb-3">
              <p class="text-gray-500 mb-1">Название</p>
              <el-input v-model="product.title"></el-input>
            </div>
          </el-col>
          <el-col :span="12" :xs="24"
            ><div class="mb-3">
              <p class="text-gray-500 mb-1">Бренд</p>
              <el-input v-model="product.brand"></el-input></div
          ></el-col>
          <el-col :span="12" :xs="24"
            ><div class="mb-3">
              <p class="text-gray-500 mb-1">SKU</p>
              <el-input
                :disabled="product_id !== 'new'"
                v-model="product.article"
              ></el-input></div
          ></el-col>
          <el-col :span="12" :xs="24">
            <div class="mb-3">
              <p class="text-gray-500 mb-1">SKU (Kaspi)</p>
              <div class="flex items-center">
                <el-input v-model="product.kaspi_article"></el-input>
                <a
                  v-if="product.kaspi_article"
                  class="text-blue-400 ml-2"
                  target="_blank"
                  :href="
                    'https://kaspi.kz/shop/p/p-' + product.kaspi_article + '/'
                  "
                >
                  <el-button>Перейти</el-button>
                </a>
              </div>
            </div>
          </el-col>

          <el-col :span="24"
            ><div class="mb-3">
              <p class="text-gray-500 mb-1">Дилерская цена</p>
              <el-input
                type="number"
                v-model.number="product.dealer_price"
              ></el-input></div
          ></el-col>

          <el-col>
            <div class="mt-5 text-right">
              <el-button
                @click="deleteProduct"
                v-if="product_id !== 'new'"
                size="small"
                plain
                round
                type="danger"
              >
                Удалить
              </el-button>
              <el-button
                plain
                round
                type="primary"
                size="small"
                @click="saveProduct"
              >
                Сохранить
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <!--      v-if="user && [1, 2, 22, 32, 34, 35].includes(user.id)"-->

      <template v-if="product_id !== 'new' && product.article">
        <div
          v-for="market in ['kaspi', 'halyk']"
          :key="market"
          class="border mb-5 rounded-md"
        >
          <div class="w-full">
            <BranchAvailability
              v-for="branch in getMarketBranches(market)"
              :key="branch.id"
              :branch="branch"
              :sku="product.article"
              :dealerPrice="product.dealer_price"
              :market="market"
            ></BranchAvailability>
          </div>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import {
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
} from "@/api/product";
import { getBranches } from "@/api/branch";
import BranchAvailability from "@/components/BranchAvailability";
import { dateTimeFormat } from "@/helpers/formatter";

export default {
  name: "Product",
  components: { BranchAvailability },
  data: () => ({
    product: {
      title: null,
      brand: null,
      article: null,
      kaspi_article: null,
      dealer_price: null,
    },
    branches: [],
    loading: false,
  }),
  computed: {
    product_id() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    if (this.product_id !== "new") {
      this.loading = true;
      const serverData = await getProduct(this.product_id);
      const { sku, brand, title, kaspi_sku, dealer_price } = serverData.product;
      this.product = {
        article: sku,
        kaspi_article: kaspi_sku,
        brand,
        title,
        dealer_price,
      };
      this.loading = false;
    }
    this.branches = await getBranches();

    let kaspiButton = document.createElement("script");
    kaspiButton.setAttribute("id", "KS-Widget");
    kaspiButton.setAttribute(
      "src",
      "https://kaspi.kz/kaspibutton/widget/ks-wi_ext.js"
    );
    document.head.appendChild(kaspiButton);
  },
  mounted() {},
  methods: {
    getMarketBranches(market) {
      if (market === "kaspi") {
        return this.branches.filter((branch) => !!branch.branch_code);
      }
      return this.branches.filter((branch) => !!branch.branch_code_halyk);
    },
    async saveProduct() {
      if (this.product_id === "new") {
        await this.createProduct();
      } else {
        await this.updateProduct();
      }
    },

    async deleteProduct() {
      try {
        this.loading = true;
        const result = await deleteProduct(this.product_id);
        if (result.success) {
          this.$message.success("Успешно");
          this.$router.back();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    async createProduct() {
      try {
        this.loading = true;
        const result = await createProduct({ ...this.product });
        if (result.success) {
          this.$message.success("Успешно");
          this.$router.push({ params: { id: result.article } });
          setTimeout(() => {
            location.reload();
          }, 1000);
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    async updateProduct() {
      try {
        this.loading = true;
        const result = await updateProduct(this.product_id, {
          ...this.product,
        });
        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    dateTimeFormat(timestamp) {
      return dateTimeFormat(timestamp * 1000);
    },
  },
};
</script>

<style scoped></style>
